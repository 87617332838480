exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aree-tsx": () => import("./../../../src/pages/aree.tsx" /* webpackChunkName: "component---src-pages-aree-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-servizi-holter-cardiaco-tsx": () => import("./../../../src/pages/servizi/holter-cardiaco.tsx" /* webpackChunkName: "component---src-pages-servizi-holter-cardiaco-tsx" */),
  "component---src-pages-servizi-ordine-veloce-tsx": () => import("./../../../src/pages/servizi/ordine-veloce.tsx" /* webpackChunkName: "component---src-pages-servizi-ordine-veloce-tsx" */),
  "component---src-pages-servizi-tampone-covid-19-tsx": () => import("./../../../src/pages/servizi/tampone-covid-19.tsx" /* webpackChunkName: "component---src-pages-servizi-tampone-covid-19-tsx" */),
  "component---src-pages-servizi-tsx": () => import("./../../../src/pages/servizi.tsx" /* webpackChunkName: "component---src-pages-servizi-tsx" */)
}

